import styles from './style.module.css'

const About = () => {
    return (
    <div className={styles.container}>
        <h1>На странице "О сайте" пока ничего нет, но скоро появится! :) </h1>
    </div>
    );
}


export default About;