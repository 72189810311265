import styles from './style.module.css'

const Lost = () => {
    return (
    <div className={styles.container}>
        <h1 className={styles.text}>На этой странице пока ничего нет, ждите обновлений! :) </h1>
    </div>
    );
}


export default Lost;